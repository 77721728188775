import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';
import Link from 'components/ui/link';

import newFilters from 'img/blog/updates/new_filters_art.png';

const Content = () => {
  return (
    <div>
      <p>
        We&apos;ve implemented the method that lets you identify users and add custom properties to
        his sessions (you can learn more{' '}
        <Link href="/help/user-identification-custom-properties/" target="_blank">
          here
        </Link>
        ). Thanks to this, you can easily check how&apos;re your users doing and provide better
        support. Use emails and names in filters to get session replays of specific users.
      </p>
      <ImageModal src={newFilters} alt="Filters" title="New filters in LiveSession" />
      <p className="mt-3">
        To make your job easier we&apos;ve added autocompletion of search value. It was easy before
        to make a typo and hard to browse collected data. That&apos;s why we&apos;ve implemented
        this feature. It lists all of you values with a number of occurrences per property. You can
        see it on screenshot above.
      </p>
      <p>Thanks for your attention and stay tuned. </p>
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'User parameters in filters and autocomplete',
  url: '/blog/new-filters-released/',
  description: 'From now on, you can filter sessions by username and email',
  author,
  img: newFilters,
  date: '2018-09-22',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
